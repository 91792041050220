import React from 'react';
import { Layout } from '@components';
import styled from 'styled-components';
import { media, Main } from '@styles';

const Container = styled(Main)`
  h1 {
    font-size: 200px;
    line-height: 1;
    ${media.tablet`
      font-size: 20vw;
    `};
  }
  img {
    max-width: 400px;
  }
`;

const NotFoundPage = () => (
  <Layout>
    <Container>
      <h1>404</h1>
      <p>Oops, there&apos;s nothing here!</p>
      <p>
        Looking for a job? Check out our <a href="https://www.upstatement.com/jobs/">jobs page</a>!
      </p>
      <img src="https://media.giphy.com/media/VMZooHBxnTwty/giphy.gif" alt="Sad cat" />
    </Container>
  </Layout>
);

export default NotFoundPage;
